import request from '@/utils/request';

// 详情
export function detail() {
  return request({
    url: '/config/detail',
    method: 'get',
  });
}

// 修改
export function save(data) {
  return request({
    data,
    url: '/config/save',
    method: 'post',
  });
}
