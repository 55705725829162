<template>
  <div>
    <lz-card title="兑换系统-公告" v-if="$route.name == 'config-tip'">
      <el-button slot="right" type="primary" @click="handleSubmit">保存</el-button>
      <lz-form :form="form" :col="1" ref="lzForm"></lz-form>
    </lz-card>
    <lz-card title="一件代发系统-公告" v-if="$route.name == 'shipping-notice'">
      <el-button slot="right" type="primary" @click="handleSubmitShipping">保存</el-button>
      <lz-form :form="shippingForm" :col="1" ref="lzShippingForm"></lz-form>
    </lz-card>
    <lz-card title="操作文档" v-if="$route.name == 'shipping-article'">
      <el-button slot="right" type="primary" @click="handleSubmitArticle">保存</el-button>
      <lz-form :form="articleFormm" :col="1" ref="lzArticleForm"></lz-form>
    </lz-card>
  </div>
</template>
<script>
import { detail, save } from '@/api/system';

export default {
  data() {
    return {
      form: [
        { fieldName: 'exchange_content', type: 'textarea', name: '登录提示语', value: '', minRows: 5 },
        { fieldName: 'exchange_list_content', type: 'textarea', name: '详情提示语', value: '', minRows: 5 },
      ],
      shippingForm: [
        { fieldName: 'content', type: 'textarea', name: '代发提示语', value: '', minRows: 5 },
      ],
      articleFormm: [
        { fieldName: 'content', type: 'editor', name: '详细信息', value: '', required: false, width: '100%' },
      ],
    };
  },
  mounted() {
    if (this.$route.name === 'config-tip') {
      detail().then((response) => {
        this.form.forEach((element) => {
          this.$set(element, 'value', response.data[element.fieldName]);
        });
      });
    }
    if (this.$route.name === 'shipping-notice') {
      this.$baseHttp.get('/shipping/notice/detail').then((response) => {
        this.shippingForm[0].value = response.data.content;
      });
    }
    if (this.$route.name === 'shipping-article') {
      this.$baseHttp.get('/shipping/article/detail').then((response) => {
        this.articleFormm[0].value = response.data.content;
      });
    }
  },
  methods: {
    async handleSubmit() {
      const params = this.$refs.lzForm.getValue();
      const response = await save(params);
      if (!response) return;
      this.$message.success('操作成功');
    },
    async handleSubmitShipping() {
      const params = this.$refs.lzShippingForm.getValue();
      params.title = '代发提示语';
      params.id = 22;
      const response = await this.$baseHttp.post('/shipping/admin/notice/save', params);
      if (!response) return;
      this.$message.success('操作成功');
    },
    async handleSubmitArticle() {
      const params = this.$refs.lzArticleForm.getValue();
      params.title = '操作手册';
      params.id = 21;
      const response = await this.$baseHttp.post('/shipping/admin/article/save', params);
      if (!response) return;
      this.$message.success('操作成功');
    },
  },
};
</script>
